





















.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
