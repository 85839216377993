body {
  margin: 0;
}

body {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100vh;
  font-weight: 400;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.router-link-border {
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  margin: 2px 12px 0;
}

.router-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  color: #808080;
  cursor: pointer;
}

.router-link-container {
  margin: 0 -12px;
}

#nav a.router-link-active,
.router-link-active {
  border-color: #333;
  color: #333;
}

[class^="c-icon-"] {
  color: #333333;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track{
  background: #e7e7e7;
}
  
*::-webkit-scrollbar-thumb{
  background: hsla(0, 0%, 53%, 0.8);
  border-radius: 3px;
}
  
*::-webkit-scrollbar-thumb:hover{
  background: hsla(0, 0%, 53%, 1);
}

@import 'table.scss';
@import 'status.scss';
@import 'common.scss';