



























































.dialog {
  &__title {
    padding: 17px 24px;
  }

  &__content {
    background: #f5f5f5;
    border: 1px solid #e7e7e7;
    padding: 24px;
  }

  &__footer {
    padding: 12px 24px;
  }
}
