.status {
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  border-radius: 2px;
  padding: 2px 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 8px;
  letter-spacing: 0.02em;
  margin: 0 15px;
  height: 12px;
  color: #333333;
  background: #e7e7e7;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    background-color: #9E6C0C;
    width: calc(100% - 8px);
    left: 4px;
    bottom: 3px;
  }
}
