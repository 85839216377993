

















































































































.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 0 34px;
  height: 64px;
  box-shadow: inset 0px -1px 0px #e7e7e7;

  &__left-side,
  &__right-side {
    display: flex;
    align-items: center;
  }

  &__url {
    color: #333;
  }

  &__logo {
    margin: 0;
    display: inline-block;
  }

  &__navigation {
    margin: 0 60px;
  }

  .settings {
    margin: 0 26px;
    font-size: 20px;
  }

  &__user-name {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    margin-left: 11px;
  }

  &__value {
    margin: 0 6px;
  }

  &__info {
    cursor: pointer;
  }
}
