


















.skeleton-wave {
  display: inline-block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.skeleton-wave:after {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  -webkit-animation: skeleton-wave 1.5s linear 0.5s infinite;
  animation: skeleton-wave 1.5s linear 0.5s infinite;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

@keyframes skeleton-wave {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}
